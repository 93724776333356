import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ErrorModal = props => {
  const { t } = useTranslation();

  function createMarkup(content) {
    return { __html: content };
  }

  return (
    <Modal id="error-modal" show={!!props.error} onHide={props.onClear} scrollable={true} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">{t('error.occurred')}</Modal.Title>
      </Modal.Header>
      <Modal.Body><div dangerouslySetInnerHTML={createMarkup(props.error)}></div></Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClear}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
