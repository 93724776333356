import React, { lazy } from 'react';
import { useSelector } from "react-redux";
import { Route, Redirect, Switch } from 'react-router-dom';
import Chat from '../shared/components/Chat/chat';

const Header = lazy(() => import('./../shared/components/Navigation/Header'));
const PrivateRoute = lazy(() => import('./PrivateRoute'))
const Homepage = lazy(() => import('./../homepage/homepage'));
const Auth = lazy(() => import('./../user/pages/Auth'));
const AuthSAML = lazy(() => import('./../user/AuthSAML'));
const Logout = lazy(() => import('./../user/Logout'));
const JoinRoom = lazy(() => import('./../user/pages/JoinRoom'));
const History = lazy(() => import('./../history/pages/History'));
const CallDetail = lazy(() => import('./../history/pages/CallDetail'));
const Users = lazy(() => import('./../user/pages/Users'));
const NewUser = lazy(() => import('./../user/pages/NewUser'));
const ImportUsers = lazy(() => import('./../user/pages/ImportUsers'));
const UpdateUser = lazy(() => import('./../user/pages/UpdateUser'));
const Groups = lazy(() => import('./../group/pages/Groups'));
const NewGroup = lazy(() => import('./../group/pages/NewGroup'));
const UpdateGroup = lazy(() => import('./../group/pages/UpdateGroup'));
const Recordings = lazy(() => import('./../recording/pages/Recordings'));

const Routes = () => {
    const token = useSelector((state) => state.auth.token);

    let routes = (
        <Switch>
            <Route path="/auth">
                {token ? (
                    <Redirect to={{ pathname: "/calendar" }} />
                ) : (
                    <Auth />
                )}
            </Route>
            <Route path="/authSAML"><AuthSAML /></Route>
            <Route path="/logout"><Logout /></Route>
            <Route path="/room-not-available/:type">
                <JoinRoom />
            </Route>
            <PrivateRoute path="/calendar" exact>
                <Header />
                <Homepage />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/users" exact>
                <Header />
                <Users />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/history" exact>
                <Header />
                <History />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/history/:callId">
                <Header />
                <CallDetail />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/users/new" exact>
                <Header />
                <NewUser />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/users/import" exact>
                <Header />
                <ImportUsers />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/users/:userId">
                <Header />
                <UpdateUser />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/groups" exact>
                <Header />
                <Groups />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/groups/new" exact>
                <Header />
                <NewGroup />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/groups/:groupId">
                <Header />
                <UpdateGroup />
                <Chat />
            </PrivateRoute>
            <PrivateRoute path="/recordings" exact>
                <Header />
                <Recordings />
                <Chat />
            </PrivateRoute>
            <Redirect to="/calendar" />
        </Switch>
    )

    return (<main>{routes}</main>)
}

export default Routes
