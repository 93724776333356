import React, { useEffect, useCallback } from "react";
import BandyerSDK from "@bandyer/bandyer-chat-widget";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Chat = () => {
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);
	const CALL_ENABLED = useSelector((state) => state.auth.permissions.CALL_ENABLED);
	const { i18n } = useTranslation();

	const fetchUserChatInfo = useCallback(
		async (alias) => {
			try {
				const response = await axios({
					method: "GET",
					url: `${process.env.REACT_APP_BACKEND_URL}api/users/chat-info/${alias}`,
					data: null,
					headers: {
						Authorization: "Bearer " + token,
					},
				});
				const responseData = await response.data;
				return responseData;
			} catch (err) {}
		},
		[token]
	);

	const userDetailsProvider = useCallback(
		(usersAlias) => {
			const userObjPromises = [];
			usersAlias.forEach((alias) => {
				const user = fetchUserChatInfo(alias);
				userObjPromises.push(user);
			});
			return Promise.all(userObjPromises);
		},
		[fetchUserChatInfo]
	);

	const userDetailsFormatter = (user) => {
		if (!user.firstName) return user.userAlias;
		else return `${user.firstName} ${user.lastName}`;
	};

	const getSdkCredentials = async () => {
		try {
			const response = await axios({
				method: "GET",
				url: `${process.env.REACT_APP_BACKEND_URL}api/auth/bandyer/sdk/credentials`,
				data: null,
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			const responseData = await response.data;
			return responseData.access_token;
		} catch (err) {}
	};

	useEffect(() => {
		if (!user?.bandyerUserId) return;
		const initChat = async () => {
			if (!BandyerSDK.isAuthenticated()) {
				const client = await BandyerSDK.configure({
					region: "eu",
					userAlias: user.bandyerUserId,
					appId: process.env.REACT_APP_BANDYER_WEBAPP_ID,
					environment: process.env.REACT_APP_BANDYER_ENVIRONMENT,
					hidden: !!(user.permissions & CALL_ENABLED) ? false : true,
					chat: !!(user.permissions & CALL_ENABLED) ? true : false,
					tools: {
						chat: true,
						screen_sharing: true,
						file_upload: false,
						whiteboard: true,
						snapshot: true,
						live_edit: true,
						live_pointer: true,
					},
					recordingType: "manual",
					mode: "window",
					language: "en",
					layout: {
						header: {
							background: "linear-gradient(to top, #f30056, #e4002b)",
						},
						launcher: {
							background: "#e4002b",
						},
						messageSent: {
							background: "#e4002b",
							color: "#fff",
						},
						fontFamily: "Montserrat",
					},
					userDetailsProvider,
					userDetailsFormatter,
				});
				client.on("client:access_token:is_about_to_expire", async () => {
					let accessToken = await getSdkCredentials();
					await client.updateAccessToken(accessToken);
				});
				let accessToken = await getSdkCredentials();
				client.connect(user.bandyerUserId, accessToken);
				window.bandyerClient = client;
				if (!!!(user.permissions & CALL_ENABLED)) {
					client.on("call_ended", (call) => {
						client.hideWidget();
					});
					client.on("call_dial_declined", (call) => {
						client.hideWidget();
					});
				}
			} else {
				window?.bandyerClient.showWidget();
			}
		};
		initChat();
		return async () => {
			if (BandyerSDK.isAuthenticated()) {
				await window?.bandyerClient.disconnect();
				BandyerSDK.destroyClient();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, i18n.language, userDetailsProvider, CALL_ENABLED]);

	return <></>;
};

export default Chat;
