import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { logout } from "./authSlice";

export const request = createAsyncThunk(
    "app/request",
    async ({ url, method = 'GET', data = null, headers = {} }, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const response = await axios({
                method,
                url,
                data,
                headers
            });
            if (response.status === 200)
                return fulfillWithValue(response.data)
            else {
                if (typeof response.data === 'string')
                    return rejectWithValue(response.data)
            }
        } catch (error) {
            let e = typeof error.response !== 'undefined' ? error.response.data : error.message;
            if (e === 'Authentication failed!') {
                dispatch(logout());
            }
            return rejectWithValue(error.response.data)
        }
    }
)

export const appSlice = createSlice({
    name: "app",
    initialState: {
        isLoading: false,
        error: false
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload.text;
        },
        clearError: (state, action) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(request.pending, (state) => {
                state.isLoading = true;
                state.error = false;
            })
            .addCase(request.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(request.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },

});

export const { setError, clearError } = appSlice.actions;

export default appSlice.reducer;