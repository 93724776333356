import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "./appSlice"

export const getCalls = createAsyncThunk("history/getCalls", async (params, { dispatch, getState }) => {
    const { location } = params;
    const state = getState();
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/history/calls/${location.search}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const getCall = createAsyncThunk("history/getCall", async (params, { dispatch, getState }) => {
    const { callId } = params;
    const state = getState();
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/history/${callId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const historySlice = createSlice({
    name: "history",
    initialState: {
        startDate: null,
        endDate: null,
        calls: [],
        pagination: {
            hasNextPage: false,
            hasPreviousPage: false,
            lastPage: 1,
            nextPage: 2,
            previousPage: 0,
            totalItems: 0
        },
        selectedCall: {}
    },
    reducers: {
        setStartDate: (state, action) => {
            state.startDate = action.payload.startDate;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload.endDate;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCalls.pending, (state) => {
                state.calls = []
            })
            .addCase(getCalls.fulfilled, (state, action) => {
                // console.log(action)
                const { results, pagination } = action.payload;
                if (results) {
                    state.calls = results;
                    state.pagination = pagination;
                }
            })
            .addCase(getCall.pending, (state) => {
                state.selectedCall = []
            })
            .addCase(getCall.fulfilled, (state, action) => {
                // console.log(action)
                if (typeof action.payload !== "string") {
                    state.selectedCall = action.payload;
                }
            })
    }
});

export const { setStartDate, setEndDate } = historySlice.actions;

export default historySlice.reducer;