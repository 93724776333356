import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "./appSlice"

export const getEvents = createAsyncThunk("events/get", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const { eventId } = params;
    const method = eventId ? 'GET' : 'POST';
    const path = eventId ? `${eventId}` : '';
    const data = eventId ? null : params;
    const state = getState();
    const req = await dispatch(request({
        method,
        url: `${process.env.REACT_APP_BACKEND_URL}api/rooms/${path}`,
        data,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const addEvent = createAsyncThunk("events/add", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}api/rooms/create/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const updateEvent = createAsyncThunk("events/update", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'PATCH',
        url: `${process.env.REACT_APP_BACKEND_URL}api/rooms/edit/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const deleteEvent = createAsyncThunk("events/delete", async ({ eventId }, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_BACKEND_URL}api/rooms/${eventId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const notifyEvent = createAsyncThunk("events/notify", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}api/rooms/notify`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const searchParticipants = createAsyncThunk("events/searchParticipants", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const { search } = params;
    const state = getState();
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/users/search-participants?${search}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const eventsSlice = createSlice({
    name: "events",
    initialState: {
        events: [],
        selectedEvent: false
    },
    reducers: {
        removeSelectedEvent: (state) => {
            state.selectedEvent = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEvents.pending, (state, action) => {
                const { start } = action.meta.arg;
                if (start)
                    state.events = []
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                // console.log(action)
                const { start } = action.meta.arg;
                if (start && typeof action.payload !== "string") {
                    state.events = action.payload.map(event => {
                        return {
                            ...event,
                            color: '#e4002b',
                            textColor: '#FFFFFF'
                        }
                    });
                }
                else {
                    if (typeof action.payload !== "string")
                        state.selectedEvent = action.payload;
                }
            })
    }
});

export const { removeSelectedEvent } = eventsSlice.actions;

export default eventsSlice.reducer;