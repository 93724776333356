import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "./appSlice"

export const getUsers = createAsyncThunk("users/getUsers", async (params, { dispatch, getState }) => {
    const { showDeleted, location } = params;
    const state = getState();
    let url = showDeleted ? 'users/deleted/' : 'users/';
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/${url}${location.search}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const addUser = createAsyncThunk("users/addUser", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}api/users/add/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const updateUser = createAsyncThunk("users/update", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'PATCH',
        url: `${process.env.REACT_APP_BACKEND_URL}api/users/edit/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const deleteUser = createAsyncThunk("users/delete", async ({ userId }, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_BACKEND_URL}api/users/${userId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const restoreUser = createAsyncThunk("users/restore", async ({ userId }, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/users/restore/${userId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
        pagination: {
            hasNextPage: false,
            hasPreviousPage: false,
            lastPage: 1,
            nextPage: 2,
            previousPage: 0,
            totalItems: 0
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.users = []
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                // console.log(action)
                const { users, pagination } = action.payload;
                if (users) {
                    state.users = users;
                    state.pagination = pagination;
                }
            })
    }
});

// export const {  } = usersSlice.actions;

export default usersSlice.reducer;