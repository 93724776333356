import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { request } from "./appSlice";

const storedData = JSON.parse(localStorage.getItem('userData'));
const hasStoredData = storedData && storedData.token && new Date(storedData.expiration) > new Date();

export const login = createAsyncThunk("auth/login", async (credentials, { dispatch }) => {
    const req = await dispatch(request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}api/auth/login`,
        data: credentials
    }));
    return req.payload;
});

export const qrcode = createAsyncThunk("auth/qrcode", async (credentials, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/auth/qrcode`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const loginWithToken = createAsyncThunk("auth/loginWithToken", async (params, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const { token } = params;
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/auth/getUser`,
        headers: {
            Authorization: 'Bearer ' + token
        }
    }));
    if (!req.error)
        return fulfillWithValue(req.payload);
    else
        return rejectWithValue(req.payload);
});

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isLoggedIn: hasStoredData ? storedData.isLoggedIn : false,
        user: hasStoredData ? storedData.user : null,
        token: hasStoredData ? storedData.token : null,
        expiration: hasStoredData ? new Date(storedData.expiration).toISOString() : null,
        permissions: {
            AD_USER: 1,
            MANAGE_USERS: 2,
            GLOBAL_VISIBILITY: 4,
            GROUP_VISIBILITY: 8,
            CALL_ENABLED: 16,
            VISIBLE_FROM_ALL: 32,
            MANAGE_RECORDINGS: 64
        }
    },
    reducers: {
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.expiration = null;
            localStorage.removeItem('userData');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                // console.log(action)
                if (!action.payload) return;
                const { user, token, expirationDate } = action.payload;
                if (!user) return;
                state.isLoggedIn = true;
                state.user = user;
                state.token = token;
                state.expiration = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60).toISOString();
                localStorage.setItem(
                    'userData',
                    JSON.stringify({
                        isLoggedIn: true,
                        user,
                        token: token,
                        expiration: expirationDate
                    })
                );
            })
            .addCase(loginWithToken.fulfilled, (state, action) => {
                // console.log(action)
                if (!action.payload) return;
                const { user, token, expirationDate } = action.payload;
                if (!user) return;
                state.isLoggedIn = true;
                state.user = user;
                state.token = token;
                state.expiration = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60).toISOString();
                localStorage.setItem(
                    'userData',
                    JSON.stringify({
                        isLoggedIn: true,
                        user: state.user,
                        token: token,
                        expiration: expirationDate
                    })
                );
            })
    },
});

export const { logout, loginSAML } = authSlice.actions;

export default authSlice.reducer;