import React, { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.scss";
import Loading from "./shared/components/UIElements/Loading";
import ErrorModal from "./shared/components/UIElements/ErrorModal";
import { Routes } from "./routes";
import { clearError } from "./redux/appSlice";

const App = () => {
	const error = useSelector((state) => state.app.error);
	const dispatch = useDispatch();
	return (
		<Router>
			<Suspense fallback={<Loading fullPage={true} />}>
				<Routes />
				<ErrorModal error={error} onClear={() => dispatch(clearError())} />
			</Suspense>
		</Router>
	);
};

export default App;
