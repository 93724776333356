import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import authReducer from "./authSlice";
import eventsReducer from "./eventsSlice";
import usersReducer from "./usersSlice";
import groupsreducer from "./groupsSlice";
import recordingsReducer from "./recordingsSlice";
import historyReducer from "./historySlice";

export default configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    events: eventsReducer,
    users: usersReducer,
    groups: groupsreducer,
    recordings: recordingsReducer,
    history: historyReducer,
  }
});