import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Loading = props => {
    return (
        <Container className={`vertical-center ${props.fullPage && 'fullpage'}`}>
            <Row>
                <Col>
                    <div className="loader">
                        <svg className="circular" viewBox="25 25 50 50">
                            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
                        </svg>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Loading;