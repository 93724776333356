import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { request } from "./appSlice"

export const getRecordings = createAsyncThunk("recordings/get", async (params, { dispatch, getState }) => {
    const { location, recordingId } = params;
    const path = recordingId ? recordingId : location.search
    const state = getState();
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/recordings/${path}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const requestRecording = createAsyncThunk("recordings/session/", async (params, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const { sessionId } = params;
    const state = getState();
    const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/recordings/session/${sessionId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    });
    if (response.status === 200)
        return fulfillWithValue(response.data)
    else {
        if (typeof response.data === 'string')
            return rejectWithValue(response.data)
    }
});

export const addRecording = createAsyncThunk("recordings/add", async (params, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}api/recordings/add/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const updateRecording = createAsyncThunk("recordings/update", async (params, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'PATCH',
        url: `${process.env.REACT_APP_BACKEND_URL}api/recordings/edit/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const deleteRecording = createAsyncThunk("recordings/delete", async ({ recordingId }, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_BACKEND_URL}api/recordings/${recordingId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const permanentlyDeleteRecording = createAsyncThunk("recordings/permanentlyDelete", async ({ recordingId }, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_BACKEND_URL}api/recordings/permanentlyDelete/${recordingId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const recordingsSlice = createSlice({
    name: "recordings",
    initialState: {
        recordings: [],
        pagination: {
            hasNextPage: false,
            hasPreviousPage: false,
            lastPage: 1,
            nextPage: 2,
            previousPage: 0,
            totalItems: 0
        },
        selectedRecording: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRecordings.pending, (state) => {
                state.recordings = []
            })
            .addCase(getRecordings.fulfilled, (state, action) => {
                // console.log(action)
                const { recordings, pagination } = action.payload;
                if (recordings) {
                    state.recordings = recordings;
                    state.pagination = pagination;
                }
                else {
                    state.selectedRecording = action.payload;
                }
            })
    }
});

// export const {  } = recordingsSlice.actions;

export default recordingsSlice.reducer;