import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "./appSlice"

export const getGroups = createAsyncThunk("groups/get", async (params, { dispatch, getState }) => {
    const { location, groupId } = params;
    const path = groupId ? groupId : location.search
    const state = getState();
    const req = await dispatch(request({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}api/groups/${path}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const addGroup = createAsyncThunk("groups/add", async (params, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}api/groups/add/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const updateGroup = createAsyncThunk("groups/update", async (params, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'PATCH',
        url: `${process.env.REACT_APP_BACKEND_URL}api/groups/edit/`,
        data: params,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const deleteGroup = createAsyncThunk("groups/delete", async ({ groupId }, { dispatch, getState }) => {
    const state = getState();
    const req = await dispatch(request({
        method: 'DELETE',
        url: `${process.env.REACT_APP_BACKEND_URL}api/groups/${groupId}`,
        data: null,
        headers: {
            Authorization: 'Bearer ' + state.auth.token
        }
    }));
    return req.payload;
});

export const groupsSlice = createSlice({
    name: "groups",
    initialState: {
        groups: [],
        pagination: {
            hasNextPage: false,
            hasPreviousPage: false,
            lastPage: 1,
            nextPage: 2,
            previousPage: 0,
            totalItems: 0
        },
        selectedGroup: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGroups.pending, (state) => {
                state.groups = []
            })
            .addCase(getGroups.fulfilled, (state, action) => {
                // console.log(action)
                const { groups, pagination } = action.payload;
                if (groups) {
                    state.groups = groups;
                    state.pagination = pagination;
                }
                else {
                    state.selectedGroup = action.payload;
                }
            })
    }
});

// export const {  } = groupsSlice.actions;

export default groupsSlice.reducer;